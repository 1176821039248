import * as types from './../../actions/user/userTypes';
import initialState from './../initialState';

export default function currentUserReducer(state = initialState.currentUser, action) {
    switch (action.type) {
        // case types.UPDATE_USER_LANGUAGE_SUCCESS:
        //     let newState = Object.assign({}, state, action.currentUser);
        //     newState.data = {...newState.data, lang: action.lang}
        //     return newState;
        case types.LOAD_CURRENT_USER_SUCCESS:
            return Object.assign({}, state, action.currentUser);
        default:
            return state;
    }
}