import * as types from './../../actions/configuration/configurationTypes';
import initialState from './../initialState';

export default function configurationReducer(state = initialState.configuration, action) {
    switch (action.type) {
        case types.LOAD_CONFIGRUATION_SUCCESS:
            return Object.assign({}, state, action.configuration);
        default:
            return state;
    }
}