import * as appStorage from "../../appStorage";

export const emptyState = {
    currentUser: {},
    configuration: {},
    formResources: {
        formConfiguration: {},
        bondings: [],
        involveds: [],
        manifestations: [],
    },
};

const persistedState = appStorage.getPersistedReduxState();

const initialState = persistedState ? Object.assign({}, emptyState, persistedState) : emptyState;

export default initialState;