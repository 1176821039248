import Validator from "validatorjs";

export const setValidatorLang = (language) => {
    Validator.useLang(language);
};

export const registerCustomValidators = () => {
    let messages = Validator.getMessages(Validator.getDefaultLang());

    Validator.register('in_array_object', (value, list) => {
        if (Array.isArray(value)) {
            let match = true;
            value.forEach(item => {
                if (!list.some(x => x.value === item.value)) {
                    match = false;
                }
            });
            return match;
        } else {
            return list.some(item => item.value === value.value);
        }
    }, messages.in);
};