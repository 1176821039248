import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {DEFAULT_LANGUAGE} from "./constants";

let params = {v: `${Date.now()}`};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: DEFAULT_LANGUAGE,
        supportedLngs: ["es-ES" /*, "en-GB", "es-PE"*/],
        fallbackLng: "es-ES",
        // fallbackLng: {
        //     "es-PE": ["es-ES", "en-GB"],
        //     "default": ["es-ES", "en-GB", "es-PE"]
        // },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
            queryStringParams: params
        },
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',
        debug: false,
        react: {
            useSuspense: true,
        }
    });

export default i18n;