import React, {lazy, Suspense, useEffect} from 'react';
import * as $ from 'jquery';
import './styles/app.scss';
import {BrowserRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import i18n from './i18n';
import {registerCustomValidators, setValidatorLang} from "./utils/validatorFunctions";
import * as moment from "moment-timezone";
import {registerLocale} from "react-datepicker";

window.jQuery = $;

const Routes = lazy(() => import('./Routes'));

// Disable warning "Synchronous XMLHttpRequest on the main thread is deprecated.."
$.ajaxPrefilter(o => o.async = true);

function App() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (process.env.PUBLIC_URL || '/');

    useEffect(() => {
        i18n.on('languageChanged', () => {
            const shortCode = i18n.language.split('-')[0];
            setValidatorLang(shortCode);
            registerCustomValidators();

            import(`moment/locale/${shortCode}`).then(module => {
                moment.locale(shortCode);
            });

            import(`date-fns/locale`).then(module => {
                registerLocale(shortCode, module[shortCode]);
            });

        });
    }, []);

    return (
        <Suspense fallback={<div></div>}>
            <BrowserRouter basename={basename} getUserConfirmation={(result) => {
                console.log("result");
            }}>
                <Routes/>
            </BrowserRouter>
            <div className={"loader-wrapper"}>
                <div className={"loader"}>
                </div>
            </div>
        </Suspense>
    );
}

const mapStateToProps = (state) => {
    return {
        // redirectToLogin: state.redirectToLogin,
    }
};

export default connect(mapStateToProps)(App);
// export default App;
