import axios from 'axios';
import decode from 'jwt-decode';
import {LOGIN_TOKEN_KEY} from '../../constants';
import {ethicalLineCache, ethicalLineLogin,} from "../../endpoint";
import * as appStorage from "../../appStorage";
import history from './../../history';

/**
 * Login a user.
 * @param payload
 * @returns {Promise.<executor|*|CancelToken>}
 */
export async function loginUser(payload) {

    axios.defaults.headers.common['Authorization'] = '';
    const loginRequest = await ethicalLineLogin.post('/users/login', payload).then(response => response.data);

    const loginToken = loginRequest.token;
    setLoginToken(loginToken);

    axios.defaults.headers.common['Authorization'] = `Bearer ${loginToken}`;
    ethicalLineLogin.defaults.headers.common['Authorization'] = `Bearer ${loginToken}`;
    ethicalLineCache.defaults.headers.common['Authorization'] = `Bearer ${loginToken}`;

    return loginToken;
}

export function logoutUser() {
}

/**
 * Destroy user session and logout.
 */
export function logout() {
    // store.dispatch(userActions.logout(true));
    appStorage.removeItem(LOGIN_TOKEN_KEY, true);
    history.go("/login");
    console.log('authService.logout');
    clearLoginToken();
}

/**
 * Destroy user session and logout.
 */
export function logoutNotRedirect() {
    clearLoginToken();
}

export function requireAuth(nextState, replace) {
    if (!isLoggedIn()) {
        replace({pathname: '/'});
    }
}

export function getLoginToken() {
    return appStorage.getItem(LOGIN_TOKEN_KEY, true);
}

export function getLoginTokenData() {
    let token = appStorage.getItem(LOGIN_TOKEN_KEY, true);
    return token ? decode(token) : null;
}

export function getCurrentUserId() {
    let token = appStorage.getItem(LOGIN_TOKEN_KEY, true);
    let dataToken = token ? decode(token) : null;
    return dataToken ? dataToken.id : null;
}

export function getCurrentUserKey() {
    let token = localStorage.getItem(LOGIN_TOKEN_KEY, true);
    let dataToken = token ? decode(token) : null;
    return dataToken ? dataToken.key : null;
}

export function getCurrentUserEmail() {
    let token = appStorage.getItem(LOGIN_TOKEN_KEY, true);
    if (!token) {
        return null;
    } else {
        let dataToken = decode(token);
        return dataToken ? dataToken.email : null;
    }
}

export function clearLoginToken() {
    console.log('authService.clearLoginToken');
    appStorage.removeItem(LOGIN_TOKEN_KEY, true);
}

// Helper function that will allow us to extract the access_token and id_token
function getParameterByName(name) {
    let match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

// Get and store id_token in local storage
export function setLoginToken(token) {
    // let idToken = getParameterByName(LOGIN_TOKEN_KEY);
    appStorage.setItem(LOGIN_TOKEN_KEY, token, true);
}

export function isLoggedIn() {
    const loginToken = getLoginToken();
    return !!loginToken && !isTokenExpired(loginToken);
}

function getTokenExpirationDate(encodedToken) {
    const token = decode(encodedToken);
    if (!token.exp) {
        return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);

    return date;
}

function isTokenExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}