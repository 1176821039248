import {APP_HASH_KEY, APP_VERSION_KEY} from "./constants";
import SecureLS from "secure-ls";
import {getCurrentUserId, getCurrentUserKey} from "./api/security/authService";

const STORAGE_KEY = 'ethical-line-app';
const REDUX_STORAGE_KEY = 'redux-state';

const getUserStorageKey = () => `${STORAGE_KEY}${getCurrentUserId() ? `-${getCurrentUserId()}` : ''}`;
const getUserReduxStorageKey = () => `${REDUX_STORAGE_KEY}${getCurrentUserId() ? `-${getCurrentUserId()}` : ''}`;

export const removePublicAppStorage = () => {
    localStorage.removeItem('ethical-line-app');
    localStorage.removeItem('redux-state');
};

export const openBox = () => {
    try {
        if (getCurrentUserId()) {
            try {
                const STORAGE_USER_KEY = getUserStorageKey();
                const STORAGE_USER_REDUX_KEY = getUserReduxStorageKey();
                const key = getCurrentUserKey();
                const ls = new SecureLS({isCompression: false, encodingType: 'aes', encryptionSecret: key});
                if (localStorage.getItem(STORAGE_USER_KEY)) {
                    const dataApp = ls.get(STORAGE_USER_KEY);
                    localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(dataApp));
                }

                if (localStorage.getItem(STORAGE_USER_REDUX_KEY)) {
                    const dataRedux = ls.get(STORAGE_USER_REDUX_KEY);
                    localStorage.setItem(STORAGE_USER_REDUX_KEY, JSON.stringify(dataRedux));
                }
            } catch (e) {
                console.log("e: ", e);
            }
        }
    } catch (e) {
        rescueLocalStorage(e);
    }
};

export const closeBox = () => {
    try {
        if (getCurrentUserId()) {
            const STORAGE_USER_KEY = getUserStorageKey();
            const STORAGE_USER_REDUX_KEY = getUserReduxStorageKey();
            const key = getCurrentUserKey();
            const ls = new SecureLS({isCompression: false, encodingType: 'aes', encryptionSecret: key});
            if (localStorage.getItem(STORAGE_USER_KEY)) {
                const dataApp = JSON.parse(localStorage.getItem(STORAGE_USER_KEY));
                ls.set(STORAGE_USER_KEY, dataApp);
            }

            if (localStorage.getItem(STORAGE_USER_REDUX_KEY)) {
                const dataRedux = JSON.parse(localStorage.getItem(STORAGE_USER_REDUX_KEY));
                ls.set(STORAGE_USER_REDUX_KEY, dataRedux);
            }
        } else {
            clearUserStorage();
        }
    } catch (e) {
        rescueLocalStorage(e);
    }
};

export const setItem = (name, value, global = false) => {
    try {
        if (global) {
            localStorage.setItem(name, value);
        } else {
            const STORAGE_USER_KEY = getUserStorageKey();
            const appStorageContainer = localStorage.getItem(STORAGE_USER_KEY) ? JSON.parse(localStorage.getItem(STORAGE_USER_KEY)) : {};
            try {
                appStorageContainer[name] = JSON.parse(value);
            } catch (e) {
                appStorageContainer[name] = value;
            }
            localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(appStorageContainer));
        }
    } catch (e) {
        rescueLocalStorage(e);
    }
};

export const getItem = (name, global = false) => {
    try {
        if (global) {
            return localStorage.getItem(name);
        } else {
            const STORAGE_USER_KEY = getUserStorageKey();
            const appStorageContainer = localStorage.getItem(STORAGE_USER_KEY) ? JSON.parse(localStorage.getItem(STORAGE_USER_KEY)) : {};
            if (!appStorageContainer[name]) return null;
            if (typeof appStorageContainer[name] === "object") {
                //Esto es para no alterar la forma en que se procesaba usando localStorage y cada llamada parsea el dato
                return JSON.stringify(appStorageContainer[name]);
            }
            return appStorageContainer[name];
        }
    } catch (e) {
        rescueLocalStorage(e);
    }
};

export const removeItem = (name, global) => {
    try {
        if (global) {
            return localStorage.removeItem(name);
        } else {
            const STORAGE_USER_KEY = getUserStorageKey();
            const appStorageContainer = localStorage.getItem(STORAGE_USER_KEY) ? JSON.parse(localStorage.getItem(STORAGE_USER_KEY)) : {};
            if (!appStorageContainer[name]) return;
            delete appStorageContainer[name];
            localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(appStorageContainer));
        }

    } catch (e) {
        rescueLocalStorage(e);
    }
};

export const clearAll = (keysPersist = []) => {
    for (var i = 0; i < localStorage.length; i++) {
        if (keysPersist.indexOf(localStorage.key(i)) !== -1) continue;
        localStorage.removeItem(localStorage.key(i));
    }
};

export const clearKeys = (keys = []) => {
    for (var i = 0; i < keys.length; i++) {
        localStorage.removeItem(keys[i]);
    }
};

export const getPersistedReduxState = () => {
    //TODO: Save store using current user id
    try {
        const STORAGE_USER_REDUX_KEY = getUserReduxStorageKey();
        const rawState = localStorage.getItem(STORAGE_USER_REDUX_KEY);
        if (rawState === null) return undefined;
        const state = JSON.parse(rawState);

        // override to reset value
        // ex. state.resetValue = 0;

        return state;
    } catch (e) {
        rescueLocalStorage(e);
    }
};

export const saveReduxState = state => {
    try {
        let stateFilter = JSON.parse(JSON.stringify(state)); // deep clone
        const rawState = JSON.stringify(stateFilter);
        const STORAGE_USER_REDUX_KEY = getUserReduxStorageKey();
        localStorage.setItem(STORAGE_USER_REDUX_KEY, rawState)
    } catch (e) {
        rescueLocalStorage(e);
    }
};

export const clearUserStorage = (keysPersist = [], keepCurrentUser = false) => {
    keysPersist = [
        ...keysPersist,
        APP_VERSION_KEY,
        APP_HASH_KEY
    ];
    const userStorageKey = getUserStorageKey();
    const userReduxStorageKey = getUserReduxStorageKey();
    const keysToRemove = [];
    for (var i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (keysPersist.indexOf(key) !== -1) continue;
        if ((key === userStorageKey || key === userReduxStorageKey) && !keepCurrentUser) {//Remove current user
            keysToRemove.push(key);
        } else if (key.indexOf('ethical-line-app-') === -1 && key.indexOf('redux-state-') === -1) { //Keep another user session data
            keysToRemove.push(key);
        }
    }
    clearKeys(keysToRemove);
};

const rescueLocalStorage = e => {
    if (e && e.name === "SyntaxError") {
        localStorage.clear();
        window.location.reload();
    }
};