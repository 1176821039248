import * as types from './../../actions/form-configuration/formConfigurationTypes';
import initialState from './../initialState';

export default function formResourcesReducer(state = initialState.formResources, action) {
    switch (action.type) {
        case types.LOAD_FORM_RESOURCES_SUCCESS:
            return Object.assign({}, state, action.formResources);
        default:
            return state;
    }
}