import React from 'react';
import ReactDOM from 'react-dom';
// import './styles/index.scss';
import '@mdi/font/scss/materialdesignicons.scss';
import 'font-awesome/scss/font-awesome.scss';
import './styles/shared/style.scss';
import './styles/layout/style.scss';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './redux/store/store'
import 'bootstrap/dist/js/bootstrap.bundle.min';

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
