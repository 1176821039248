import {
    API_BASE,
} from './constants';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import {setupCache} from 'axios-cache-adapter';
import {
    createAuthCacheInstance, createAuthInstance, createInstance,
} from "./utils/endpointFunctions";

// Create `localforage` instance
const store = localforage.createInstance({
    // List of drivers used
    driver: [
        localforage.INDEXEDDB,
        localforage.LOCALSTORAGE,
        memoryDriver._driver
    ],
    // Prefix all storage keys to prevent conflicts
    name: 'ethical-line-cache'
});

// Create `axios-cache-adapter` instance
const cache = setupCache({
    maxAge: 60 * 60 * 24 * 365 * 1000,  //30 días,
    store
});

export const ethicalLineLogin = createInstance(API_BASE);

export const ethicalLineApi = createAuthInstance(API_BASE);

export const ethicalLineCache = createAuthCacheInstance(API_BASE, cache);