import {getLoginToken, logout} from "../api/security/authService";
import axios from 'axios';
import {closeBox} from "../appStorage";
import {UNAUTHORIZED_ERROR_CODE} from "../constants";

export const createInstance = (url) => {
    return axios.create({
        baseURL: url
    });
};

export const createAuthInstance = (url) => {

    const instance = axios.create({
        baseURL: url,
        headers: {
            common: {}
        }
    });
    interceptRequest(instance);
    interceptResponse(instance);
    return instance;
};

export const createAuthCacheInstance = (url, cacheObj) => {

    const instance = axios.create({
        baseURL: url,
        headers: {
            common: {}
        },
        adapter: cacheObj.adapter
    });
    interceptRequest(instance);
    interceptResponse(instance);
    return instance;
};


export const interceptRequest = instance => {
    instance.interceptors.request.use(function (config) {
        config.headers.Authorization = `Bearer ${getLoginToken()}`;
        return config;
    });
};

export const interceptResponse = endpoint => {
    endpoint.interceptors.response.use(function (response) {
        return response;
    }, function (error) {

        console.log("ERROR", error);
        if (error.response && error.response.status === UNAUTHORIZED_ERROR_CODE) {
            closeBox();
            logout();
        } else {
            return Promise.reject(error);
        }
    });
};