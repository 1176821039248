import * as appStorage from "../../appStorage";

/* Use an IIFE to export the persisted state in a variable */
export const persistedState = (() => {
    try {
        return appStorage.getPersistedReduxState();
    } catch (err) {
        return undefined;
    }
})();

/* Export a method to save state on each store update */
export const saveState = (state) => {
    try {
        appStorage.saveReduxState(state);
    } catch (err) {
        // Ignore write errors.
    }
};