export const APP_VERSION_KEY = '1.0.0';
export const APP_HASH_KEY = 'appHash';
export const API_BASE = process.env.REACT_APP_API_BASE || 'https://api-linea-etica.net360.biz';

export const LOGIN_TOKEN_KEY = 'login_token';
export const UNAUTHORIZED_ERROR_CODE = 401;
export const DEFAULT_LANGUAGE = 'es-ES';

export const FORM_TYPE = {
    SHOW: 'SHOW',
    NEW: 'NEW',
    EDIT: 'EDIT',
};

export const COLORS = {
    PRIMARY: '#FF4F5A',
    SUCCESS: '#00e093',
};

export const REPORT_FORM = {
    STORAGE_KEY: 'report_form_model',
    STEP_KEY: 'report_form_step',
};