import {combineReducers} from 'redux';

import {emptyState} from "./initialState";
import {getPersistedReduxState} from "../../appStorage";

import currentUser from './user/currentUserReducer';
import {LOAD_USER_STATE, LOGOUT_USER} from "../actions/user/userTypes";
import configuration from './configuration/configurationReducer';
import formResources from './form-configuration/formResourcesReducer';

const reducers = {
    currentUser,
    configuration,
    formResources,
};

const appReducer = combineReducers(reducers);

// export default rootReducer;
//
// const appReducer = combineReducers({
//     /* your app’s top-level reducers */
// })

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER) {
        console.log(`%c User logout!`, 'background: red; color: white');
        console.log("state: ", state);
        state = {...emptyState}
    } else if (action.type === LOAD_USER_STATE) {
        const persistedState = getPersistedReduxState(true);
        if (persistedState) {//the user had logged in another time
            state = {...persistedState};
        }
    }

    return appReducer(state, action)
};

export default rootReducer;