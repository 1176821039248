import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import {persistedState, saveState} from './persisted.store.js';

const middleware = process.env.NODE_ENV !== 'production' ?
    [require('redux-immutable-state-invariant').default(), thunk] :
    [thunk];

export default function configureStore() {
    // const composeEnhancers = composeWithDevTools({ realtime: true, port: 8000 });

    const composeEnhancers = process.env.NODE_ENV !== 'production' ?
        ((typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose) : compose;

    const enhancer = composeEnhancers(
        applyMiddleware(...middleware),
        // other store enhancers if any
    );

    const store = createStore(
        rootReducer,
        persistedState,
        enhancer
    );

    // add a listener that will be invoked on any state change
    store.subscribe(() => {
        saveState(store.getState());
    });

    return store;
};